import React, { useState , useEffect,useRef , useContext} from "react";
import { AppBar,Toolbar,Typography ,Grid, TextField ,Stack} from "@mui/material"
import { makeStyles } from "@material-ui/core/styles";
import companylogo from "../assets/data_axle.PNG"
import bgLogo from "../assets/body_bg.png"
import Button from "@mui/material/Button";
import FormLabel from "@mui/material/FormLabel";
import { useNavigate, useSearchParams ,Link} from "react-router-dom"
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";
import api from "../Api";
import Box from "@mui/material/Box";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { display } from "@mui/system";
import { axiosInstance } from "../axios";
import Avatar from '@mui/material/Avatar';
import businessUnitContext from "../context/businessUnitContext";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

const useStyles = makeStyles( theme =>({
 
    header: {
      backgroundImage : `url(${bgLogo})`,
      height:"65px"
    //   backgroundRepeat:"repeat-x"

    },
    
    select: {
      color:"white",
      "&:after": {
        borderBottomColor: "darkred",
        
      },
      "& .MuiSvgIcon-root": {
        color: "cyan",
      },
    },
     }))
const Header=({user,setDynamicSideBarData,businessunit,setLoading,loading})=>{
  const classes = useStyles();
  const navigate= useNavigate();
  const [businessunitdata,setBusinessunitdata]= useState([])
  const [selectedBU,setSelectedBU] =useState(localStorage.getItem("BU"))
  const [anchor,setAnchor] =useState(null)
  const open = Boolean(anchor)
  const [ searchParams , setSearchParams] = useSearchParams();
  const prevBU = useRef();
  const [ bu , setBu]= useContext(businessUnitContext);
  const [ openDialog , setOpenDialog]= useState(false);
  const [ newbusinessunit , setNewBusinessUnit] = useState('')
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openProfile = Boolean(anchorEl);
  const handleClick = (event) => {
  
    setAnchorEl(event.currentTarget);
  };
  const handleCloseLogout = () => {
    setAnchorEl(null);
    delete axiosInstance.defaults.headers.common["Authorization"];
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    window.location.href = "/login";
  };
  const handleCloseProfile=()=>{
    setAnchorEl(null);
  }
useEffect(()=>{
  if(searchParams.get("token"))
  {
   
  localStorage.setItem("access_token",searchParams.get("token"));
  localStorage.setItem("refresh_token",searchParams.get("token"));
  
  axiosInstance.defaults.headers["Authorization"]="Bearer "+localStorage.getItem("access_token");
  axiosInstance.defaults.headers["businessunit"]=localStorage.getItem("BU");
  
  }
  callBusinessUnits();
},[])

    const callBusinessUnits=async()=>{
   try{
   
     const response= await api.getBusinessUnits();  
    setBusinessunitdata(response?.data?.BusinessUnits)

   }catch(e)
   {

   }
    }


  
  useEffect(()=>{
setLoading(true)
   const callSideBarAPI = async()=>{
    try{
   const res =await  api.getSideBarData()
   const user= JSON.parse(localStorage.getItem('Profile'))
  const last_login_BU = (localStorage.getItem('BU'))

   const switchUserApi = await api.switchBusinessUnit(user.user_id,{"last_businessiunit_name":last_login_BU});
   setDynamicSideBarData(res?.data);
   setLoading(false);
    }catch(e)
    {

    }
   }
  callSideBarAPI()
  },[selectedBU,bu])
  const handleChange =(e) =>{
   
    setAnchor(e.currentTarget)
    
  }
  const handleClose=(e)=>{ 
   
   if(e.target.innerText )
   { 
    prevBU.current = e.target.innerText
    setSelectedBU(e.target.innerText)
    businessunit(e.target.innerText)
    localStorage.setItem("BU",e.target.innerText)
   }
 else{
 setOpenDialog(true)
 }
    setAnchor(null)
  }
  const  createBussinessUnit=async()=>{
  try{

   setOpenDialog(false)
   setNewBusinessUnit('')
  }catch(e)
  {

  }

  }
    return <>
    <AppBar className={classes.header}> 
       <Toolbar  sx={{ pl: 3 }}>
       <Typography variant="h2" component="div" sx={{ flex:"1",width:"300", display: {flexDirection:'column' } }}>
        <Grid container >
          <Grid item md={2} xs={2} sx={{ display: { xs:"none", md: "flex" }}} >
            <Link  to = "/admin/dashboard">
          <img src={companylogo} align="left" alt="data axle" height="50px" style={{paddingTop:10}}/>
          </Link>
          </Grid>
       <Grid item  justifyContent="center" sx={{
          marginLeft: `${bu && bu.length > 12 ? 0 : 100}px`, // Conditionally set marginLeft
        }}>
        <FormLabel sx={{ color: "white",fontSize:30,marginLeft:28,paddingBottom:0,marginBottom:5}}>{localStorage.getItem("BU")}</FormLabel>
        {/* </Grid>
        <Grid item md={2} xs={2} justifyContent="right" sx={{ display:{md:"flex"}}}> */}
        <IconButton onClick={ handleChange}>
        <MenuOpenIcon sx={{color:"cyan"}} fontSize="large"/>
        </IconButton>
        <Menu open={open} anchorEl={anchor} onClose={handleClose} onChange={e=>{console.log(e,"Mnu")}}>

          {businessunitdata?.map(bu=>{
            return <MenuItem key={bu} value={bu} onClick={handleClose}>{bu}</MenuItem>
          })}
        </Menu>
        </Grid>
        </Grid>
        </Typography>
        
        {/* <IconButton onClick={ handleChange}>
        <MenuOpenIcon sx={{color:"white"}} fontSize="large"/>
        </IconButton>
        <Menu open={open} anchorEl={anchor} onClose={handleClose} onChange={e=>{console.log(e,"Mnu")}}>

          {businessunitdata?.map(bu=>{
            return <MenuItem key={bu} value={bu} onClick={handleClose}>{bu}</MenuItem>
          })}
        </Menu> */}
     
         <Stack direction="row" spacing={2} sx={{paddingTop:"6px"}}>
         <div>
          <Button variant="contained" sx={{color:"white",fontWeight:"bold",marginTop:"8px"}}
         onClick={()=>navigate("/admin/create_incident")}>Create Incident</Button>
         </div>
        {/* <Typography> */}
        
          {/* <FormLabel sx={{color:"white",mr:2}} disableTypography='true' > */}
            {/* {localStorage.getItem('user')} */}
            <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 ,mb:2}}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar
       sx={{ color:"black"}}
        alt="Remy Sharp"
        // src="/broken-image.jpg"
      >
         {localStorage.getItem('user')?.charAt(0)?.toUpperCase()}
      </Avatar>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openProfile}
        onClose={handleCloseProfile}
        onClick={handleCloseProfile}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        
        <MenuItem onClick={handleCloseProfile}>
          
        {localStorage.getItem('user')}
        </MenuItem>
        
        <MenuItem onClick={handleCloseLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
            {/* </FormLabel> */}
            {/* </Typography> */}
      
        
         </Stack>
       
       </Toolbar > 
  
         </AppBar> 

        </>
}
export default Header;